import _process from "process";
var exports = {};
var process = _process;
var isWindows = process.platform === "win32"; // Regex to split a windows path into into [dir, root, basename, name, ext]

var splitWindowsRe = /^(((?:[a-zA-Z]:|[\\\/]{2}[^\\\/]+[\\\/]+[^\\\/]+)?[\\\/]?)(?:[^\\\/]*[\\\/])*)((\.{1,2}|[^\\\/]+?|)(\.[^.\/\\]*|))[\\\/]*$/;
var win32 = {};

function win32SplitPath(filename) {
  return splitWindowsRe.exec(filename).slice(1);
}

win32.parse = function (pathString) {
  if (typeof pathString !== "string") {
    throw new TypeError("Parameter 'pathString' must be a string, not " + typeof pathString);
  }

  var allParts = win32SplitPath(pathString);

  if (!allParts || allParts.length !== 5) {
    throw new TypeError("Invalid path '" + pathString + "'");
  }

  return {
    root: allParts[1],
    dir: allParts[0] === allParts[1] ? allParts[0] : allParts[0].slice(0, -1),
    base: allParts[2],
    ext: allParts[4],
    name: allParts[3]
  };
}; // Split a filename into [dir, root, basename, name, ext], unix version
// 'root' is just a slash, or nothing.


var splitPathRe = /^((\/?)(?:[^\/]*\/)*)((\.{1,2}|[^\/]+?|)(\.[^.\/]*|))[\/]*$/;
var posix = {};

function posixSplitPath(filename) {
  return splitPathRe.exec(filename).slice(1);
}

posix.parse = function (pathString) {
  if (typeof pathString !== "string") {
    throw new TypeError("Parameter 'pathString' must be a string, not " + typeof pathString);
  }

  var allParts = posixSplitPath(pathString);

  if (!allParts || allParts.length !== 5) {
    throw new TypeError("Invalid path '" + pathString + "'");
  }

  return {
    root: allParts[1],
    dir: allParts[0].slice(0, -1),
    base: allParts[2],
    ext: allParts[4],
    name: allParts[3]
  };
};

if (isWindows) exports = win32.parse;else
  /* posix */
  exports = posix.parse;
exports.posix = posix.parse;
exports.win32 = win32.parse;
export default exports;
const _posix = exports.posix,
      _win = exports.win32;
export { _posix as posix, _win as win32 };